import React from 'react';
import { BaseComponent } from '..';
import history from "../../history";
import config from '../../config';
import { ActionsApi } from '../../api/Actions';
import { BalanceApi } from '../../api';


export default class CommonActions extends BaseComponent<any, any> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            loading: false,
            isGemelExsist: false,
            isHishtalmutExsist: false

        }

    }

    moveTopage = (formTypeId: any) => {
        this.setState({
            loading: true
        })

        const type = formTypeId;

        formTypeId = formTypeId == 132 ? 3 : formTypeId;
        ActionsApi.get(formTypeId).then(res => {

            switch (type) {
                case 2: window.location.href = `${config.API_URL}/forms/transfer-between-courses/${res}`;
                    break;
                case 132: window.location.href = `${config.API_URL}/forms/hishtalmut/${res}`;
                    break
                case 3: window.location.href = `${config.API_URL}/forms/withdrawal/${res}`;
                    break;
                // case 4: window.location.href = `${config.API_URL}/forms/beneficiaries/${res}`;
                //     break;
                // case 7: window.location.href = `${config.API_URL}/forms/billing-instruction/${res}`
                //     break;
                // case 15: window.location.href = `${config.API_URL}/forms/joining-investment/${res}`
                //     break;
                // case 8: window.location.href = `${config.API_URL}/forms/joining-hishtalmut/${res}`
                //     break;
                default:
                    break;

            }


        }).finally(() => {
            this.setState({
                loading: false
            })
        });



    }


    componentDidMount() {
        BalanceApi.get().then(res => {

            const prodTypes = Array.from(new Set<number>((res?.products).map((item: any) => item.prodType)));
            this.setState({
                isGemelExsist: prodTypes.includes(4) ? true : false,
                isHishtalmutExsist: prodTypes.includes(2) ? true : false
            })
        });
    }



    render() {
        return <>
            <div className='common-action-page'>

                <div className='sub-title'>
                    <span>  פעולות נפוצות </span>

                </div>

                {
                    this.state.loading ? <div style={{ textAlign: "center", paddingTop: "2em" }}>
                        <img src="/img/loading.gif" alt="loading" />
                    </div> : <>


                        <div className='wrap-img-desc'>
                            <img onClick={() => { this.moveTopage(2) }} className='image-view' src='img/mobile-action-img/Group_18834.png' />
                            <img onClick={() => {
                                window.open('https://www.yl-invest.co.il/uploads/n/1727781310.1614.pdf')
                            }} className='image-view' src='img/mobile-action-img/Group_18883.png' />
                            {this.state.isHishtalmutExsist && <img onClick={() => { this.moveTopage(132) }} className='image-view' src='img/mobile-action-img/Group_18877.png' />}
                            {!this.state.isHishtalmutExsist && this.state.isGemelExsist && <img onClick={() => { this.moveTopage(3) }} className='image-desc' src='img/mobile-action-img/Group_188344.png' />}

                            {!this.state.isHishtalmutExsist && !this.state.isGemelExsist &&
                            <img onClick={() => {
                                window.open('https://www.yl-invest.co.il/uploads/n/1727790861.3880.pdf')
                            }} className='image-view'  src='img/mobile-action-img/Group_18884.png' />  }


                            <img onClick={() => {
                                history.push("/customers/home/all-actions")

                            }} className='image-view' src='img/mobile-action-img/Group_18837.png' />
                        </div>

                        <div className='wrap-img-mobile'>
                            <img onClick={() => { this.moveTopage(2) }} className='image-view' src='img/mobile-action-img/Group_18868.png' />
                            <img onClick={() => {
                                window.open('https://www.yl-invest.co.il/uploads/n/1727781310.1614.pdf')
                            }} className='image-view' src='img/mobile-action-img/Group_18883.png' />

                            {this.state.isHishtalmutExsist && <img onClick={() => { this.moveTopage(132) }} className='image-view' src='img/mobile-action-img/Group_18856.png' />}




                            {!this.state.isHishtalmutExsist && this.state.isGemelExsist && <img onClick={() => { this.moveTopage(3) }} className='image-desc' src='img/mobile-action-img/Group_18887.png' />}

                            {!this.state.isHishtalmutExsist && !this.state.isGemelExsist &&
                            <img onClick={() => {
                                window.open('https://www.yl-invest.co.il/uploads/n/1727790861.3880.pdf')
                            }} className='image-view'  src='img/mobile-action-img/Group_18891.png'  />  }
                           
                            <img onClick={() => {
                                history.push("/customers/home/all-actions")

                            }} className='image-view' src='img/mobile-action-img/Group_18837.png' />
                        </div>

                    </>}

            </div>


            <style>
                {" "}
                {
                    "@media (max-width:991px) { .wrap-img-desc{display:none !important} }"
                }{" "}
            </style>
            <style>
                {" "}
                {
                    "@media (min-width:991px) { .wrap-img-mobile{display:none !important} }"
                }{" "}
            </style>
        </>
    }
}
