// DepositSelfEmployed.tsx
import React, { useState, useEffect } from "react";
import BasicTable from '../../CustomTable';
import { ApprovalsApi } from "../../../api/Approvals";
import { PopupService } from "../../Macros/Popup";

interface DepositSelfEmployedProps {
  isDisplayMessage: (value: boolean) => void;
  isShow: boolean;
}

interface TransactionData {
  accountNumber: string;
  valueDate: string;
  fundName: string;
  totalAmount: number;
  transactionId: number;
}

const headerColumns = [
  { header: 'מספר חשבון' },
  { header: 'תאריך ערך הפקדה' },
  { header: 'סוג קופה' },
  { header: 'סכום פעולה' }
];

const DepositSelfEmployed: React.FC<DepositSelfEmployedProps> = ({
  isDisplayMessage,
  isShow
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<TransactionData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleDownload = () => {
    if (pdfFile) {
      const newTab = window.open('about:blank', '_blank');
      const url = URL.createObjectURL(pdfFile);
      if (newTab) {
        newTab.location.href = url;
      }
      setTimeout(() => URL.revokeObjectURL(url), 1000);
      setPdfFile(null)
    }
  };

  const renderSuccessMessage = () => (
    <div>
      <div id="collapseFilter" className="collapse filter-block__collapse show">
        <div className="data-filter__row">
          <div className="data-filter__inputs">
            <div className="data-filter__inputs-row">
              <div className="col-auto data-filter__col">
                {"בקשתך התקבלה. האישור יוצג באזור האישי ב- \"דואר שלי\" תוך זמן קצר. במועד הפקת האישור, ישלח SMS למספר הטלפון הנייד המעודכן בילין לפידות"}
              </div>
            </div>
            {pdfFile && (
              <div className="col-auto main-dashboard__btn-col">
                <button
                  type="button"
                  className="main-dashboard__btn"
                  onClick={handleDownload}
                >
                  לצפיה והורדה לחץ כאן
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const fetchData = async (page: number = 1) => {
    setIsLoading(true);
    const currentDate = new Date();
    const previousYear = currentDate.getFullYear() - 1;
    const previousYearDate = new Date(previousYear, 0, 1);

    try {
      const response = await ApprovalsApi.getDepositData({
        accountId: null,
        fromDate: previousYearDate,
        pageNo: page,
        pageSize: 20,
      });
      setActions(response.transactions);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching deposit data:', error);
      setActions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedIds(prevIds => {
      const newSelectedIds = prevIds.includes(id)
        ? prevIds.filter(item => item !== id)
        : [...prevIds, id];
      return newSelectedIds;
    });
    setPdfFile(null)
  };

  const createDepositLetter = async () => {
    setIsLoading(true);

    try {
      const res = await ApprovalsApi.createNewDeposit({
        letterTypeId: 91,
        transactionIds: selectedIds
      });

      isDisplayMessage(true);

      if (res.size > 0) {
        const file = new Blob([res], { type: 'application/pdf' });
        setPdfFile(file);
      }
    } catch (error) {
      PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
    } finally {
      setIsLoading(false);
      setSelectedIds([]);
    }
  };

  const handlePaging = (page: number) => {
    setCurrentPage(page);
  };

  const relevantData = actions.map(item => ({
    accountNumber: item.accountNumber,
    valueDate: item.valueDate,
    fundName: item.fundName,
    totalAmount: item.totalAmount,
    id: item.transactionId
  }));

  if (isShow) {
    return renderSuccessMessage();
  }

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "2em" }}>
        <img src="/img/loading.gif" alt="loading" />
      </div>
    );
  }

  return (
    <>
      <div className="desc-view">
        <BasicTable
          headerColumns={headerColumns}
          data={relevantData}
          showWhite
          notShowIcon
          isShowCheckbox
          handleCheckboxChange={handleCheckboxChange}
          totalCount={totalPages}
          paging={handlePaging}
        />
      </div>
      <div className="mobile-view">
        <BasicTable
          headerColumns={headerColumns}
          data={relevantData}
          showWhite
          notShowIcon
          colorRow={selectedIds}
          isShowCheckboxMobile
          handleCheckboxChange={handleCheckboxChange}
          totalCount={totalPages}
          paging={handlePaging}
        />
      </div>

      <div className="data-filter__buttons">
        <div className="data-filter__buttons-row">
          <div className="col-auto data-filter__buttons-col">
            <button
              disabled={selectedIds.length === 0}
              type="button"
              className={`data-filter__btn ${selectedIds.length === 0 ? 'disableButton' : ''}`}
              onClick={createDepositLetter}
            >
              שלח
            </button>
          </div>
        </div>
      </div>

      <style>
        {`
          @media (max-width:991px) { .desc-view{display:none !important} }
          @media (min-width:991px) { .mobile-view{display:none !important} }
        `}
      </style>
    </>
  );
};

export default DepositSelfEmployed;