import { BaseSecuredApi } from './BaseSecuredApi';
import { ILoginResult } from './Login';
import { UserDataService } from '../services';

export interface IGetUserDataResult {
  status?: any;
  firstname: string;
  lastname: string;
  lastLogin: string;
  maxRevaluationDate: number;
  accounts: { id: number; name: string; balance: number; courses: string[] }[];
  isUserMailingPopupNeeded: boolean;
  userRole?: number;
  lastActionAmount?: number;
  isBlockedToAction: boolean;
  isLoan: boolean;
  isTestMode: boolean;
  isEnvironmentEnableRemoteForms: boolean;
}

export interface IGetPersonalDetailsResult {
  firstName: string;
  lastName: string;
  personalId: number;
  gender: number;
  birthDate: string;
  maritalStatus: number;
  email: string;
  phone: string;
  mobile: string;
  city: { name: string; id: number };
  street: string;
  houseNumber: string;
  apartment: string;
  houseEntrance: string;
  zip: string;
  mailbox: string;
  isBiometricId: boolean;
  isVerifyEmail: boolean;
  metadata: { maritalStatusOptions: maritalStatusOptions[]; genderOptions: genderOptions[] };
  getDepositConfirmation: boolean;
  mothlyRevNotice: boolean;
  statementDeliveryType: number;
  lettrerApproved: boolean;

}

interface maritalStatusOptions {
  text: string;
  val: number;
}
interface genderOptions {
  text: string;
  val: number;
}

export interface ISubPersonalDetailsResult { }

class UserData extends BaseSecuredApi {
  constructor() {
    super('UserData');
  }

  get() {
    return this.POST<IGetUserDataResult>();
  }

  renew() {
    return this.POST<ILoginResult>({
      action: 'renew',
    });
  }

  getPersonalDetails() {
    return this.POST<IGetPersonalDetailsResult>({
      action: 'getPersonalDetails',
      // cache: true
    });
  }

  submitPersonalDetails(data: any, isHtml?: boolean) {
    if (UserDataService.isBlockedToAction) {
      return this.FORM_POST<Blob>({
        action: 'Details/update/block',
        // action: "Details/update",
        data,
        isHtml,
      });
    }
    return this.FORM_POST<any>({
      action: 'Details/update',
      // action: "Details/update",
      data,
    });
  }

  confirmPopUp(data: any) {
    return this.POST<{}>({
      action: 'Mailing/Confirm',
      cache: true,
      data,
    });
  }

  confirmMergePopup(data: any){
    return this.POST<any>({
      action: "course/merge",
      data
    })
  }
  confirmLetterPopup(data:any) {
    return this.POST<any>({
      action: "letters/Confirm",
      data
    })
  }

  confirmBirthdayPopup(){
    return this.POST<any>({
      action:"birthday/approved"
    })
  }
  getCity(data: any) {
    return this.POST<any>({
      action: 'localities',
      controller: 'cms',
      data,
    });
  }

  getPdf(data: any) {

    return this.DownloadBlob<Blob>({
      action: 'ExportAccountStreamPdf',
      data,
    }).then((res) => {
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        var file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        // window.open(fileURL, '_blank');
        return fileURL;
      }
    });
  }

  getCheckLetterConsent(){
    console.log("checking")
    
    return this.POST<any>({
      action:'CheckLetterConsent'
    })
  }

  confirmLetterApproved(){
    return this.POST<any>({
      action:'letters/Confirm',
      data:{ isApproved: true}
    })
  }



}

export const UserDataApi = new UserData();
