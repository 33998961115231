import React, { useState, useEffect } from "react";
import BasicTable from '../../CustomTable';
import { ApprovalsApi } from '../../../api/Approvals'
import { PopupService } from "../../Macros/Popup";

interface NoticeToEmployerProps {
  isDisplayMessage: (value: boolean) => void;
  isShow: boolean;
}

interface EmployerData {
  accountNumber: string;
  openingDate: string;
  isViewed: number;
  fundDisplayName: string;
  employerName: string;
  id: string;
  employerId: string;
}

const headerColumns = [
  { header: 'מספר חשבון' },
  { header: 'תאריך פתיחה' },
  { header: 'סוג קופה' },
  { header: 'שם מעסיק' }
];

const NoticeToEmployer: React.FC<NoticeToEmployerProps> = ({
  isDisplayMessage,
  isShow
}) => {
  const [choosenData, setChoosenData] = useState<EmployerData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [employerData, setEmployerData] = useState<EmployerData[]>([]);
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);

  useEffect(() => {
    fetchEmployerData();
  }, []);

  const handleDownload = () => {
    if (pdfFile) {
      const newTab = window.open('about:blank', '_blank');
      const url = URL.createObjectURL(pdfFile);
      if (newTab) {
        newTab.location.href = url;
      }
      setTimeout(() => URL.revokeObjectURL(url), 1000);
    }
  };

  const renderSuccessMessage = () => (
    <div>
      <div id="collapseFilter" className="collapse filter-block__collapse show">
        <div className="data-filter__row">
          <div className="data-filter__inputs">
            <div className="data-filter__inputs-row">
              <div className="col-auto data-filter__col">
                {"בקשתך התקבלה. האישור יוצג באזור האישי ב- \"דואר שלי\" תוך זמן קצר. במועד הפקת האישור, ישלח SMS למספר הטלפון הנייד המעודכן בילין לפידות"}
              </div>
            </div>
            {pdfFile && (
              <div className="col-auto main-dashboard__btn-col">
                <button
                  type="button"
                  className="main-dashboard__btn"
                  onClick={handleDownload}
                >
                  לצפיה והורדה לחץ כאן
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const fetchEmployerData = async () => {
    setIsLoading(true);
    try {
      const currentDate = new Date();
      const targetYear = currentDate.getFullYear() - 1;
      const previousYearDate = new Date(targetYear, 0, 1);

      const data = await ApprovalsApi.getAppendixFData({
        toDate: currentDate,
        fromDate: previousYearDate,
      });

      const formattedData = data.map((item: any, idx: number) => ({
        accountNumber: item.accountNumber,
        openingDate: item.openingDate,
        isViewed: item.fundId,
        fundDisplayName: item.fundDisplayName,
        employerName: item.employerName,
        id: idx.toString(),
        employerId: item.employerID
      }));

      setEmployerData(formattedData);
    } catch (error) {
      console.error('Error fetching Appendix F data:', error);
      PopupService.alert('שגיאה', 'אירעה שגיאה בעת טעינת הנתונים. אנא נסה שנית מאוחר יותר.', () => { });
    } finally {
      setIsLoading(false);
    }
  };

  const createLetter = async () => {
    if (!choosenData) return;

    setIsLoading(true);
    const personalId = JSON.parse(sessionStorage.getItem('user') || '{}').username;
    const type = choosenData.isViewed === 404 ? 86 : 85;
    const requestData = {
      personalId,
      employerId: choosenData.employerId,
      fundCourseId: choosenData.isViewed,
      letterTypeId: type
    };

    try {
      const res = await ApprovalsApi.createNewAppendix(requestData);
      isDisplayMessage(true);

      if (res.size > 0) {
        const file = new Blob([res], { type: 'application/pdf' });
        setPdfFile(file);
      }
    } catch (error) {
      console.error('Error creating appendix:', error);
      PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
    } finally {
      setIsLoading(false);
    }
  };

  if (isShow) {
    return renderSuccessMessage();
  }

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "2em" }}>
        <img src="/img/loading.gif" alt="loading" />
      </div>
    );
  }

  return (
    <>
      <div className="desc-view">
        <BasicTable
          headerColumns={headerColumns}
          data={employerData}
          showWhite
          notShowIcon
          isShowRadio
          selectRadio={(data: EmployerData) => setChoosenData(data)}
        />
      </div>

      <div className="mobile-view">
        <BasicTable
          headerColumns={headerColumns}
          data={employerData}
          showWhite
          notShowIcon
          isShowRadioMobile
          colorRow={choosenData ? [choosenData.id] : []}
          selectRadio={(data: EmployerData) => setChoosenData(data)}
        />
      </div>

      <div className="data-filter__buttons">
        <div className="data-filter__buttons-row">
          <div className="col-auto data-filter__buttons-col">
            <button
              disabled={!choosenData}
              type="button"
              className={`data-filter__btn ${!choosenData ? 'disableButton' : ''}`}
              onClick={createLetter}
            >
              שלח
            </button>
          </div>
        </div>
      </div>

      <style>
        {`
          @media (max-width:991px) { .desc-view{display:none !important} }
          @media (min-width:991px) { .mobile-view{display:none !important} }
        `}
      </style>
    </>
  );
};

export default NoticeToEmployer;