

import { BaseSecuredApi } from "./BaseSecuredApi";



class Actions extends BaseSecuredApi {

    constructor() {
        super("FormWizard");
    }

    get(data: any) {         
        return this.POST<any>({
            data:{
                formTypeId:data
            },
            action: "new",
        });
    }

}

export const ActionsApi = new Actions();