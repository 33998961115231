import React, { useState, useEffect } from 'react';
import CustomSelect, { ICustomSelectItem } from '../Macros/CustomSelect';
import Doc161 from './Doc161';
import DepositSelfEmployed from './DepositSelfEmployed';
import NoticeToEmployer from './NoticeToEmployer';
import { PopupService } from '../Macros/Popup';
import history from '../../history';
import { ApprovalsApi } from '../../api/Approvals';
import { UserDataApi } from '../../api/UserData';

const DOCS = ['בחר', 'טופס הכנה ל 161', 'אישור הפקדה לעצמאי', 'הודעה למעסיק על בחירת עובד (נספח ו)'];

const Approvals: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFormType, setSelectedFormType] = useState('');
  const [docKind, setDocKind] = useState<ICustomSelectItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [moveToPage, setMoveToPage] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [actions, setActions] = useState([]);
  const [form161, setForm161] = useState([]);
  const [appendixF, setAppendixF] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isShowMessage, setIsShowMessage] = useState(false);

  useEffect(() => {
    setDocKind(
      DOCS.map((x, i) => ({
        label: x,
        value: i === 0 ? '' : i.toString(),
      }))
    );

    checkLetterConsent();
  }, []);

  const checkLetterConsent = async () => {
    try {
      const res = await UserDataApi.getCheckLetterConsent();
      if (!res.approved) {
        showConsentPopup();
      } else {
        setMoveToPage(true);
      }
    } catch (error) {
      console.error('Error checking letter consent:', error);
    }
  };

  const showConsentPopup = () => {
    PopupService.show(
      'הפקת אישורים נוספים',
      `<div id="mail-popup">
        שים לב, לצורך הפקת אישורים נוספים נדרש להצטרף לשירות דיוור דיגטלי,
        שמנו לב שאינך רשום לשירות
      </div>`,
      [
        {
          caption: 'צרפו אותי לשירות',
          onClick: async () => {
            setMoveToPage(true);
            await UserDataApi.confirmLetterApproved();
            PopupService.hide();
          },
        },
        {
          caption: 'לא מעונין',
          onClick: () => {
            PopupService.hide();
            history.push('/customers/home');
          },
        },
      ],
      false
    );
  };

  const renderScreen = () => {
    setShowScreen(true);
    setIsShowMessage(false);

  };

  return (
    <>
      <div className="action-intro">
        <div className="content-wrapper">
          <div className="row action-intro__row">
            <div className="col-lg action-intro__col-text">
              <h1 className="action-intro__heading">הפקת אישורים נוספים</h1>
              <h2 className="contact-data__heading">
                כאן ניתן לקבל אישורים שונים באופן דיגיטלי. בסיום הטיפול, האישור יוצג באזור האישי ב- "דואר שלי". במועד הפקת האישור, ישלח SMS למספר הטלפון הנייד המעודכן בילין לפידות
              </h2>
            </div>
          </div>
        </div>
      </div>

      {moveToPage && (
        <div className="content-wrapper content-wrapper--sheet">
          <div className="content-sheet">
            <div className="pane-content pane-content--tax-reports">
              <section className="data-section">
                <div className="filter-block-wrapper">
                  <div className="filter-block">
                    <h3 className="filter-block__heading">סנן לפי</h3>
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFilter"
                      aria-expanded="false"
                      aria-controls="collapseFilter"
                      aria-label="toggle controls"
                      className="filter-block__toggle collapsed"
                    >
                      <span className="caption">סנן לפי</span>
                    </button>
                    <div id="collapseFilter" className="collapse filter-block__collapse show">
                      <form id="tax-reports-filter-form" className="data-filter filter-block__form">
                        <div className="data-filter__row">
                          <div className="data-filter__inputs">
                            <div className="data-filter__inputs-row">
                              <div className="col-auto data-filter__col">
                                <label htmlFor="df-date" className="sr-only"></label>
                                <CustomSelect
                                  id="df-date"
                                  buttonClassName="custom-select__input"
                                  itemClassName="custom-select__item"
                                  options={docKind}
                                  onChange={(value) => {
                                    setSelectedFormType(value);
                                    setShowScreen(false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="data-filter__buttons">
                            <div className="data-filter__buttons-row">
                              <div className="col-auto data-filter__buttons-col">
                                <button
                                  type="button"
                                  className="data-filter__btn data-filter__btn--reset"
                                  onClick={() => setShowScreen(false)}
                                >
                                  נקה
                                </button>
                              </div>
                              <div className="col-auto data-filter__buttons-col">
                                <button type="button" className="data-filter__btn" onClick={renderScreen}>
                                  הצג
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>

              {showScreen && (
                <>
                  {selectedFormType === '1' && form161 && (
                    <Doc161
                      isDisplayMessage={setIsShowMessage}
                      isShow={isShowMessage}
                    />
                  )}
                  {selectedFormType === '2' && (
                    <DepositSelfEmployed
                      isDisplayMessage={setIsShowMessage}
                      isShow={isShowMessage}
                    />
                  )}
                  {selectedFormType === '3' && (
                    <NoticeToEmployer
                      isDisplayMessage={setIsShowMessage}
                      isShow={isShowMessage}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Approvals;