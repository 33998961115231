import React from "react";
import { BaseComponent } from "..";
import { TopDucks, CmsBannersInner } from "../Macros";
import { Pagination } from "react-bootstrap";



interface IHeader {
  header: string
}

interface Iprops {
  headerColumns: IHeader[]
  printState?: () => void;
  data?: any[]
  downloadPdf?: (id: number, isViewed?: boolean) => void;
  onDelete?: (data: any) => void;
  onRender?: (page: number) => void;
  paging?: (page: number) => void;
  totalCount?: number;
  isRplaceIcons?: boolean
  selectedItems?: any
  showWhite?: boolean
  colorRow?: any;
  notShowIcon?: boolean
  isShowCheckbox?: boolean
  isShowRadio?: boolean
  isShowRadioMobile?: boolean
  isShowCheckboxMobile?: boolean
  selectRadio?: (data?: any) => void;
  handleCheckboxChange?: (id: any, isCheck: any) => void;
}

interface IState {
  currentPage: number
  numberRowsSelected: any[],
  isRadioClick: boolean;

}

export default class BasicTable extends BaseComponent<Iprops, IState> {
  cmsPageAlias = "reports";

  constructor(props: Iprops) {
    super(props);
    this.state = {
      currentPage: 1,
      numberRowsSelected: [],
      isRadioClick: true
    };
  }
  handleItemClick = (id: number) => {


    const a = JSON.parse(sessionStorage.getItem('noReadBold')) || []
    const index = a.indexOf(id);
    if (index === -1) {
      const all = [...a, id]
      sessionStorage.setItem("noReadBold", JSON.stringify(all))

    }

  };

  getPages() {


    const result: number[] = [];
    for (let i = 1; i <= this.props.totalCount; i++) {
      result.push(i);

    }
    return result;
  }

  setPage(page: number) {


    if (page !== this.state.currentPage) {
      this.setState({
        currentPage: page,
      });
      this.props.paging(page);
    }
  }
  render() {

    return (
      <>
        {
          this.props?.data?.length > 0 ?

            <div className="content-wrapper content-wrapper--sheet">
              <div className="content-sheet">
                <div className="reports-data">
                  <div className="pane-content pane-content--reports">
                    <div className="custom-table-block">
                      <div className="custom-table">
                        <div className="thead">
                          <div className="tr">
                            {
                              (this.props.isShowCheckbox || this.props.isShowRadio) && (<div className="th"> בחר </div>)
                            }

                            {

                              this.props.headerColumns.map((item) => {
                                return (
                                  <div className={`th ${item.header === "צפיה והורדה" ? 'th-style' : ''}`}>
                                    {item.header}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>

                        <div className="tbody">
                          {
                            this.props?.data?.map((item, i) => (
                              <div onClick={() => {
                                if (this.props.isShowRadioMobile) {
                                  this.props.selectRadio(item)
                                }
                                if (this.props.isShowCheckboxMobile) {

                                  console.log("this.state", this.state.isRadioClick);
                                  this.setState(prevState => ({
                                    isRadioClick: !prevState.isRadioClick,
                                  }));


                                  this.props.handleCheckboxChange(item.id, true)


                                }


                              }}
                                data-id={item.id}
                                className={`tr   ${this.props.colorRow?.includes(item.id) ? "mobile-style-blue" : this.props?.selectedItems?.includes(item.id) || this.props.showWhite ? "" : "mobile-style"}`}
                                key={i}>
                                {
                                  this.props.isShowCheckbox && (

                                    <span className="td number">
                                      <input
                                        style={{ position: 'initial', width: '15px', height: '15px' }}
                                        type="checkbox"
                                        name="rowsSelected"
                                        id="rowsSelected"
                                        className="yalin-checkbox__input"
                                        onChange={(ev) => {
                                          this.props.handleCheckboxChange(item.id, ev.target.checked)


                                        }}

                                      />
                                    </span>


                                  )
                                }
                                {

                                  this.props.isShowRadio && (
                                    <span className="td number">
                                      <input
                                        type="radio"
                                        value={1}
                                        name="rowCheck"
                                        required
                                        onChange={(ev) => {

                                          this.props.selectRadio(item)

                                        }}
                                      />
                                    </span>
                                  )
                                }
                                {Object.keys(item).map((key, index) => (
                                  key != 'id' ?
                                    key != 'isViewed' ?
                                      key != 'employerId' ?
                                        <span
                                          className={`td number ${this.props.colorRow?.includes(item.id) ? "td-blue-style" : this.props?.selectedItems?.includes(item.id) || this.props.showWhite ? "td-white-style" : "td-gray-style"} `}
                                          key={index}
                                          style={{
                                            fontWeight: this.props?.selectedItems?.includes(item.id) || this.props.showWhite ? 'normal' : 'bold',
                                          }}
                                        >

                                          {item[key]}
                                        </span>
                                        : null
                                      : null
                                    : null

                                ))}


                                {!this.props.notShowIcon && (
                                  this.props.isRplaceIcons ?
                                    <>

                                      <div
                                        className={`td td-button ${this.props?.selectedItems?.includes(item.id) || this.props.showWhite ? "td-white-style" : "td-gray-style"}`}
                                        style={{
                                          textAlign: "right",

                                        }}
                                      >
                                        <a
                                          onClick={() => {

                                            this.props.downloadPdf(item.id, item.isViewed)
                                            this.handleItemClick(item.id)

                                          }}
                                          className="save-pdf-btn"
                                          style={{ cursor: "pointer", backgroundColor: '#f7f8fb' }}

                                        >יצא <span>PDF</span>

                                        </a>
                                      </div></> :
                                    <>  <div
                                      className={`td td-button ${this.props?.selectedItems?.includes(item.id) ? "td-white-style" : "td-gray-style"}`}
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <a
                                        onClick={() => {

                                          this.props.downloadPdf(item.id, item.isViewed)
                                          this.handleItemClick(item.id)

                                        }}
                                        className="save-pdf-btn"
                                        style={{ cursor: "pointer", backgroundColor: '#f7f8fb' }}
                                      >יצא <span>PDF</span>
                                      </a>
                                    </div>
                                      <div
                                        className={`td remove ${this.props?.selectedItems?.includes(item.id) || this.props.showWhite ? "td-white-style" : "td-gray-style"}`}
                                        style={{
                                          textAlign: "right",

                                        }}
                                      >
                                        <a
                                          onClick={() => {
                                            this.props.onDelete({ id: item.id, kind: item.isViewed })
                                          }}>
                                          <img
                                            src={require("../../img//icons/remove.svg")}
                                          />
                                        </a>
                                      </div></>
                                )

                                }

                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {this.props && (


                      <Pagination
                        style={{ flexWrap: 'wrap' }}
                        size="sm"
                        className="d-flex justify-content-center flex-row-reverse p-4 "
                      >

                        {this.getPages().map((page) => (
                          <Pagination.Item
                            key={page}
                            active={page === this.state.currentPage}
                            onClick={() => this.setPage.call(this, page)}
                          >
                            {page}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    )}

                    <CmsBannersInner
                      banners={this.cmsContent && this.cmsContent.banners}
                    />
                    <style>
                      {" "}
                      {
                        ".page-link{color:#282828; font-size:16px !important;} .dropdown-close{display:none}"
                      }{" "}
                    </style>
                  </div>
                </div>
              </div>
            </div> :
            <div style={{ padding: '30px' }}> לא נמצאו תוצאות </div>
        }
      </>
    );
  }
}
