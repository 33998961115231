
import React from "react";
import BasicTable from '../CustomTable'
import BaseComponent from "../BaseComponent";
import { CustomerLetterApi, ILetters, IShowLetter } from '../../api/CustomerLetter';
import { Tab, Nav } from 'react-bootstrap';
import { PopupService } from "../Macros/Popup";
import { CustomSelect } from "../Macros";
import { UserDataService } from "../../services";
import { ICustomSelectItem } from "../Macros/CustomSelect";

interface IRequestsState {
  isLoading: boolean,
  letters: ILetters[]
  filterData: IShowLetter[],
  noReadnumber: number,
  allLetterNumber: number
  showLetter: IShowLetter[],
  totalCount: number,
  totalUnViewedCount?: number
  selectedTab: string
  noResadNumbers: any[]
  selectedItems: any[]

  selectedAccount?: string;
  accounts: ICustomSelectItem[];

  selectedYear?:any;
  years: ICustomSelectItem[];

  types: ICustomSelectItem[]
  selectedType?: string

}

const headerColumns = [
  {
    header: 'מכתב'
  },
  {
    header: 'תאריך'
  }, {
    header: 'צפיה והורדה'
  }, {
    header: 'הסרה'
  }
];

const letterType = [
  {
    label: ' כל הסוגים ',
    value: ''
  },
  {
    label: 'העברה בין מסלולים',
    value: '102'
  },
  {
    label: '	העברה לגוף אחר',
    value: '198'
  },
  {
    label: 'משיכה מקופה',
    value: '172'
  },
  {
    label: 'העברה לילין לפידות',
    value: '38'
  },
  {
    label: 'הצטרפות לילין לפידות ',
    value: '44'
  },
  {
    label: "נספח ו' גמל ",
    value: '85'
  },
  {
    label: "	נספח ו' השתלמות  ",
    value: '86'
  },
  {
    label: 'טופס 161',
    value: '88'
  },
  {
    label: '	אישור הפקדה',
    value: '91'
  }
]


export default class CustomerLetter extends BaseComponent<{}, IRequestsState> {

  constructor(props: Readonly<{}>) {
    super(props);

    const accounts = UserDataService.data.accounts.map((x) => {
      return {
        label: x.id.toString(),
        value: x.id.toString(),
      };
    });
    accounts.splice(0, 0, { label: "כל החשבונות", value: "" });


    const  allYears =  this.getYearsArray("SYBS").map(x => ({ label: x.toString(), value: x.toString() } as ICustomSelectItem))
    allYears.splice(0, 0, { label: "שנה", value: "" });


    this.state = {
      letters: null,
      isLoading: false,
      filterData: null,
      showLetter: null,
      selectedAccount: '',
      accounts: accounts,
      totalCount: 0,
      totalUnViewedCount: 0,
      noReadnumber: parseInt(sessionStorage.getItem('sumUnViewed')) || 0,
      allLetterNumber: parseInt(sessionStorage.getItem('sumLetter')) || 0,
      selectedTab: 'noRead',
      noResadNumbers: [],
      selectedItems: [],
      selectedYear: '',
      years:allYears,
      types: letterType,
      selectedType: ''
    }
    
  }

  componentDidMount() {

    // this.state.


    CustomerLetterApi.getUnReadNumber().then((res) => {
      sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
      sessionStorage.setItem("sumLetter", res.sumLetter)

      if (res) {
        if (parseInt(sessionStorage.getItem('sumUnViewed')) == res.sumUnViewed && parseInt(sessionStorage.getItem('sumLetter')) == res.sumLetter) {

          sessionStorage.setItem('noReadBold', null)
        }
        this.fetchLetterData();
      }
    })


    this.setState({
      noResadNumbers:

        this?.state?.showLetter?.map(item => ({
          displayName: item.displayName,
          letterCreatedDate: item.letterCreatedDate,
          id: item.id,
          isViewed: item.isViewed
        })
        ).filter(item => item.isViewed)?.map(item => item.id)
    })

    this.setState({
      selectedItems:
        JSON.parse(sessionStorage.getItem("noReadBold")) || this.state?.noResadNumbers || []

    })

    const a = this?.state?.showLetter?.map(item => ({
      displayName: item.displayName,
      letterCreatedDate: item.letterCreatedDate,
      id: item.id,
      isViewed: item.isViewed
    })
    ).filter(item => item.isViewed)?.map(item => item.id)

    console.log("byh", a);

  }

  componentDidUpdate(prevProps: any, prevState: IRequestsState) {
    if (prevState.selectedTab && this.state.selectedTab && prevState.selectedTab != this.state.selectedTab) {
      this.fetchLetterData()
    }
  }


  handleDelete = (data: any) => {

    PopupService.show(
      'מכתבים שיוסרו לא יוצגו בדואר שלי, האם הינך רוצה להסיר את המכתבים? ',
      `<div id="mail-popup">
      </div>`,
      [
        {
          caption: this.label("mailing_popup_confirm_btn"),
          onClick: () => {

            this.deleteLetter({ id: data.id, kind: data.kind });
            PopupService.hide();

          },
        },
        {
          caption: "לא מאשר",
          onClick: () => {

            PopupService.hide();

          },
        },
      ],
      false
    );

  }




  deleteLetter = (data: any) => {
    const selectedItems = JSON.parse(sessionStorage.getItem("noReadBold")) || []

    const checkIfIdInList = selectedItems.some((item: any) => item === data.id)


    CustomerLetterApi.delete(data.id).then((res) => {
      if (res === true) {
        this.setState(prevState => ({
          allLetterNumber: prevState.allLetterNumber - 1,
          showLetter: prevState.showLetter.filter((item) => item.id != data.id)
        }))
        if (checkIfIdInList == false) {
          this.setState(prevState => ({
            noReadnumber: prevState.noReadnumber - 1
          }))

        }

        CustomerLetterApi.getUnReadNumber().then((res) => {
          sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
          sessionStorage.setItem("sumLetter", res.sumLetter)
        })
      }
    })

      .catch((err) =>
        console.log(err)
      );
  }


  resetForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

    this.setState(
      {
        selectedType: "",
        selectedYear: "",
        selectedAccount: "",
      },
      this.fetchLetterData
    );

    event.preventDefault();
  }

  submitForm(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({},
      this.fetchLetterData);

    event.preventDefault();
  }

  handleSelect = (eventKey: string) => {

    this.setState({ selectedTab: eventKey }); // Update state when a tab is selected
  };

  fetchLetterData = (page?: number) => {

    const choosenTab = this.state.selectedTab == "noRead" ? false : null;
    this.setState({
      isLoading: true,
    });
    CustomerLetterApi.get({
      pageNo: page || 1,
      pageSize: 20,
      viewd: choosenTab,
      year: this.state.selectedYear ? parseInt(this.state.selectedYear) : null,
      accountNumber: this.state.selectedAccount ? parseInt(this.state.selectedAccount) : null,
      letterType: this.state.selectedType ? parseInt(this.state.selectedType) : null
    })
      .then((res) => {
        if (res == null) {
          this.setState({
            isLoading: false,
            letters: [],
            showLetter: []
          })
        } else {

          this.setState(prevState => ({
            isLoading: false,
            letters: res.letters,
            totalCount: choosenTab == false ? prevState.totalCount : res.totalCount,
            allLetterNumber: parseInt(sessionStorage.getItem('sumLetter')),
            noReadnumber: parseInt(sessionStorage.getItem('sumUnViewed')),
            totalUnViewedCount: parseInt(sessionStorage.getItem('sumUnViewed')),
            showLetter: res.letters.map(item => ({
              displayName: item.displayName,
              letterCreatedDate: item.letterCreatedDate,
              id: item.id,
              isViewed: item.isViewed
            })),
            selectedItems: res.letters?.map(item => ({
              displayName: item.displayName,
              letterCreatedDate: item.letterCreatedDate,
              id: item.id,
              isViewed: item.isViewed
            })
            ).filter(item => item.isViewed)?.map(item => item.id)
          })
          )
        }

      })
      .catch(() => {
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        this.setState({
          isLoading: false,
          letters: null,
        })
      });
  }

  deleteAllLetters = () => {
    CustomerLetterApi.deleteAll().then((res) => {
      if (res === true) {
        //  this.fetchLetterData()
        CustomerLetterApi.getUnReadNumber().then((res) => {
          sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
          sessionStorage.setItem("sumLetter", res.sumLetter)

          this.setState(prevState => ({
            allLetterNumber: res.sumUnViewed
          }))
        })
        this.fetchLetterData()

      }
    })
      .catch((err) =>
        console.log(err)
      );
  }

  download(letterId: string, isViewed?: boolean) {
    const selectedItems = JSON.parse(sessionStorage.getItem("noReadBold")) || []
    const checkIfIdInList = selectedItems.some((item: any) => item === parseInt(letterId))
    CustomerLetterApi.Download(letterId).then((res) => {
      if (res === null) {
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        return
      }
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        link.click();
      }
      CustomerLetterApi.getUnReadNumber().then((res) => {
        sessionStorage.setItem("sumUnViewed", res.sumUnViewed)
        sessionStorage.setItem("sumLetter", res.sumLetter)
      })
      if (checkIfIdInList == false) {
        this.setState(prevState => ({
          noReadnumber: prevState.noReadnumber - 1,
          selectedItems: [...prevState.selectedItems, parseInt(letterId)]
        }));
      }
    }).catch((err) => {
      PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
    }
    );
  }

  render() {


    return (
      <div className="customer-letter">
        {
          this.state?.letters ?
            <>
              <div className="tax-reports-intro">
                <div className="content-wrapper">
                  <h1 className="tax-reports-intro__heading">
                    הדואר שלי
                  </h1>
                </div>
              </div>
              <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                  <div className="reports-data">
                    <div className="pane-content pane-content--reports">
                      <div className="content-wrapper content-wrapper--sheet">
                        <section className="data-section">
                          <div className="filter-block-wrapper">
                            <div className="filter-block">
                              <h3 className="filter-block__heading">סנן לפי</h3>
                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseFilter"
                                aria-expanded="false"
                                aria-controls="collapseFilter"
                                aria-label="toggle controls"
                                className="filter-block__toggle collapsed"
                              >
                                <span className="caption">סנן לפי</span>
                              </button>
                              <div
                                id="collapseFilter"
                                className="collapse filter-block__collapse show"
                              >
                                <form
                                  id="reports-filter-form"
                                  className="data-filter filter-block__form"
                                >
                                  <div className="data-filter__row">
                                    <div className="data-filter__inputs">
                                      <div className="data-filter__inputs-row">
                                        <div className="col-auto data-filter__col">
                                          <label
                                            htmlFor="df-accounts"
                                            className="sr-only"
                                          >
                                            כל החשבונות
                                          </label>
                                          <CustomSelect
                                            id="df-accounts"
                                            buttonClassName="data-filter__input"
                                            options={this.state.accounts || []}
                                            value={this.state.selectedAccount}
                                            onChange={(value) => {
                                              this.setState({ selectedAccount: value });
                                            }}
                                          />
                                        </div>
                                        <div className="col-auto data-filter__col">
                                          <label htmlFor="df-date" className="sr-only">
                                            מתאריך
                                          </label>
                                          <CustomSelect
                                            id="df-date"
                                            buttonClassName="data-filter__input"
                                            options={this.state.years || []}
                                            value={this.state.selectedYear}
                                            onChange={(value) => {
                                              this.setState({ selectedYear: value });
                                            }}
                                          />
                                        </div>
                                        <div className="col-auto data-filter__col">
                                          <label htmlFor="df-type" className="sr-only">
                                            כל הסוגים
                                          </label>
                                          <CustomSelect
                                            id="df-type"
                                            buttonClassName="data-filter__input"
                                            options={this?.state?.types || []}
                                            value={this.state.selectedType}
                                            onChange={(value) => {
                                              this.setState({ selectedType: value });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="data-filter__buttons">
                                      <div className="data-filter__buttons-row">
                                        <div className="col-auto data-filter__buttons-col">
                                          <button
                                            type="button"
                                            className="data-filter__btn data-filter__btn--reset"
                                            onClick={this.resetForm.bind(this)}
                                          >
                                            נקה
                                          </button>
                                        </div>
                                        <div className="col-auto data-filter__buttons-col">
                                          <button
                                            type="button"
                                            className="data-filter__btn"
                                            onClick={this.submitForm.bind(this)}
                                          >
                                            הצג
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <Tab.Container defaultActiveKey="noRead" activeKey={this.state.selectedTab} onSelect={this.handleSelect}>
                        <div className="content-wrapper content-wrapper--sheet">
                          <div className="content-sheet">
                            <div className="tabs-container">
                              <Nav variant={null} className="nav nav-tabs">
                                <Nav.Item className="nav-item">
                                  <Nav.Link eventKey="all" className={`nav-link ${this.state.selectedTab == 'all' ? 'active-tab' : ''}  `}>
                                    {` כל הדואר ( ${this.state.allLetterNumber} )`}
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item">
                                  <Nav.Link eventKey="noRead" className={`nav-link ${this.state.selectedTab == 'noRead' ? 'active-tab' : ''}  `}>
                                    {`  לא נקרא ( ${this.state.noReadnumber} )`}
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>
                          </div>
                          {
                            (this.state.selectedTab == "all") &&
                            <div className="col-auto data-filter__buttons-col remove-all-letter">
                              <button
                                type="button"
                                className="data-filter__btn"
                                onClick={() => {
                                  PopupService.show(
                                    `${this.state.allLetterNumber - this.state.noReadnumber} 
                           מכתבים שיוסרו לא יוצגו בדואר שלי, האם הינך רוצה להסיר את המכתבים?
                           `,
                                    `<div id="mail-popup">
                               </div>`,
                                    [
                                      {
                                        caption: this.label("mailing_popup_confirm_btn"),
                                        onClick: () => {
                                          this.deleteAllLetters();
                                          PopupService.hide();
                                        },
                                      },
                                      {
                                        caption: "לא מאשר",
                                        onClick: () => {
                                          PopupService.hide();
                                        },
                                      },
                                    ],
                                    false
                                  );
                                }}
                              >
                                הסר דואר נקרא
                              </button>
                            </div>
                          }
                        </div>

                        {
                          this.state?.letters?.length > 0 ?
                            <Tab.Content className="tab-content">
                              <Tab.Pane eventKey={'all'}>
                                <BasicTable
                                  headerColumns={headerColumns}
                                  data={this.state.showLetter}
                                  selectedItems={this.state.selectedItems}
                                  downloadPdf={(id, isViewed) =>
                                    this.download(
                                      id.toString(),
                                      isViewed
                                    )
                                  }
                                  onDelete={this.handleDelete}
                                  paging={this.fetchLetterData}
                                  totalCount={Math.ceil(this.state.totalCount / 20)}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="noRead">
                                <BasicTable
                                  headerColumns={headerColumns}
                                  data={this.state.showLetter}
                                  selectedItems={this.state.selectedItems}
                                  downloadPdf={(id, isViewed) =>
                                    this.download(
                                      id.toString(),
                                      isViewed
                                    )}
                                  onDelete={this.handleDelete}
                                  paging={this.fetchLetterData}
                                  totalCount={Math.ceil(this.state.totalUnViewedCount / 20)}
                                />
                              </Tab.Pane>
                            </Tab.Content> :
                            <div style={{ padding: '30px' }}> לא נמצאו תוצאות </div>
                        }


                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div style={{ textAlign: "center", paddingTop: "2em" }}>
              <img src="/img/loading.gif" alt="loading" />
            </div>}
      </div>
    );
  }
}
