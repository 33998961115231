import React, { Fragment } from 'react';
import SimpleBar from 'simplebar';
import * as ReactBoostrap from '../../../node_modules/react-bootstrap';
import { findDOMNode } from 'react-dom';
import "simplebar/dist/simplebar.min.css";

let Dropdown = ReactBoostrap.Dropdown;

export interface ICustomSelectItem {
    label: string,
    value: string
};

interface ICustomSelectProps {
    id?: string,
    name?: string,
    required?: boolean,
    className?: string,
    buttonClassName?: string,
    itemClassName?: string,
    onChange?: (value: string) => void,
    options: ICustomSelectItem[],
    value?: string,
    label?: string,
    closeButton?: boolean
};

export class SimpleBarContainer extends React.Component<{}, {}> {

    componentDidMount() {
        const ele = findDOMNode(this) as Element;
        new SimpleBar(ele);
    }

    render() {
        return this.props.children;
    }
}

export default class CustomSelect extends React.Component<ICustomSelectProps, { selected: ICustomSelectItem }> {

    selectRef: React.RefObject<HTMLSelectElement>;

    static index = 0;
    id = "dropdown-custom-" + CustomSelect.index++;
    firstUse: boolean = true;
    constructor(props: ICustomSelectProps) {
        super(props);

        this.selectRef = React.createRef<HTMLSelectElement>();

        this.state = {
            selected: props.value && props.options && props.options.some(x => x.value === props.value) ? props.options.find(x => x.value === props.value) :
                props.options ? props.options[0] as ICustomSelectItem :
                    {} as ICustomSelectItem
        };
    }

    onSelect(selected: string) {

        this.firstUse = false;
        if (this.props.onChange) {
            this.props.onChange(selected);
        }
        this.setValue(selected);
    }

    setValue(value: string) {
        this.selectRef.current.value = value;
        this.setState({
            selected: this.props.options.find(x => x.value === value) || {} as ICustomSelectItem
        });
    }

    componentDidUpdate(prevProps: Readonly<ICustomSelectProps>) {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    }

    render() {
        const { selected } = this.state;
        return <Fragment>
            <select id={this.props.id} name={this.props.name} style={{ display: 'none' }} required={this.props.required} ref={this.selectRef}>
                {!this.props.options ? null : this.props.options.map(x => <option value={x.value} key={x.value}>{x.label}</option>)}
            </select>

            <Dropdown className={"search-select" + (this.props.className ? (' ' + this.props.className) : "")} onSelect={this.onSelect.bind(this)} defaultValue={this.props.value} >
                <Dropdown.Toggle id={this.id} className={"search-select__dropdown-toggle" + (this.props.buttonClassName ? (' ' + this.props.buttonClassName) : '')} variant={null}>
                    {isNaN(parseInt(selected.label)) || this.props.id === 'df-time' ? (this.firstUse && this.props.label ? this.props.label : selected.label) : <span className="number">{(this.firstUse && this.props.label ? this.props.label : selected.label)}</span>}
                </Dropdown.Toggle>
                <Dropdown.Menu className={"dropdown-menu-right search-select__dropdown-menu" + (this.props.closeButton ? " dropdown-menu--has-close" : "")} alignRight={true}>
                    {this.props.closeButton && <button type="button" className="dropdown-close" aria-label="close"></button>}
                    <SimpleBarContainer>
                        <div className="dropdown-content search-select__dropdown-content">
                            {!this.props.options ? null : this.props.options.map(x =>
                                <Dropdown.Item key={x.value} eventKey={x.value} className={"search-select__dropdown-item" + (this.props.itemClassName ? (' ' + this.props.itemClassName) : '')}>{isNaN(parseInt(x.label)) || this.props.id === 'df-time' ? x.label : <span className="number">{x.label}</span>}</Dropdown.Item>
                            )}
                        </div>
                    </SimpleBarContainer>
                </Dropdown.Menu>
            </Dropdown>
        </Fragment >


    }
}